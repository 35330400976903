.back-to-list {
    color: $light-text-clr;
    text-align: left;
    font-size: .875rem;
    margin-bottom: 30px;
    cursor: pointer;

    & a {
        color: $light-text-clr;
    }

    @media (max-width: $md) {
        margin-bottom: 15px;
    }
}