.mobile-menu {
    display: none;

    @media (max-width: $md) {
        flex-direction: column;
        justify-content: flex-start;
        @include sqare(100%);
        right: -100%;
        position: absolute;
        top: 0;
        overflow: hidden;
        padding: 25px 30px;
        background-color: $main-blue-clr;
        box-sizing: border-box;

        & * {
            color: #fff;
        }
    }
}

.mobile-nav {
    width: 100%;

    &__close {
        @include sqare(20px);
        background: url('../images/svg/close-cross.svg') no-repeat center/contain;
        margin: 0 0 15px auto;
        cursor: pointer;
    }

    &__phone {
        width: 100%;
        text-align: left;
        margin: 30px 0 20px;
        font-size: 1.125rem;
        font-weight: 500;
    }

    &__callback {
        width: 175px;
        padding: 10px 30px;
        margin-bottom: 40px;
    }
}

.mob-menu {

    &__accordion.ui-accordion {
        @include mobile-menu;
    }

    &__head {
        height: 50px;
        font-size: 1.15rem;
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    &__body {
        background: transparent;
        display: flex;
        flex-direction: column;
    }
}