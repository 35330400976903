.socials-row {

    display: flex;

    & a {
        display: block;
        @include sqare(24px);
        margin-right: 25px;
        background-position: center/cover;
        background-repeat: no-repeat;
    }

    & [href*=facebook] {
        background-image: url('../images/svg/facebook.svg');
    }

    & [href*=vk] {
        background-image: url('../images/svg/vk.svg');
    }

    & [href*=instagram] {
        background-image: url('../images/svg/instagram.svg');
    }
}