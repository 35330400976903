.logo {
    display: block;
    height: 100%;

    & img {
        max-height: 100%;
    }
}

.header {
    height: 75px;
    padding: 15px 0;
    box-sizing: border-box;
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 6;

    @media (max-width: $xl) {
        margin-bottom: 50px;
    }

    @media (max-width: $md) {
        height: 50px;
        padding: 10px 0;
        margin-bottom: 0;
    }

    &__inner {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        @media (max-width: $xl) {
            width: 100%;
            padding: 0 30px;
        }

        @media (max-width: $md) {
            padding: 0;
        }
    }

    &__nav {
        flex-grow: 1;

        @media (max-width: $xl) {
            position: absolute;
            bottom: -51px;
            width: 100%;
            left: 0;
        }

        @media (max-width: $md) {
            display: none;
        }

        &--mobile {
            display: none;

            @media (max-width: $md) {
                display: block;
                position: absolute;
                left: 100%;
            }
        }
    }

    &__logo {
        margin-right: 60px;
    }

    &__star {
        margin: 0 50px;

        @media (max-width: $xl) {
            margin-left: auto;
        }

        @media (max-width: $md) {
            margin-left: auto;
            margin-right: 35px;
        }
    }

    &__phone {
        margin-right: 15px;
        font-weight: 500;
        position: relative;

        @media (max-width: $md) {
            @include sqare(20px);
            overflow: hidden;
            margin: 0 35px 0 0;

            &:before {
                @include pseudo-el;
                @include sqare(100%);
                background: url('../images/svg/phone.svg') no-repeat center/contain;
            }
        }

        & a {
            color: $blue-text-clr;

            @media (max-width: $md) {
                display: block;
                @include sqare(100%);
                color: transparent;
            }
        }
    }

    &__menu-open {
        display: none;

        @media (max-width: $md) {
            display: block;
            cursor: pointer;
            @include sqare(20px);
            background: url('../images/svg/menu-burger.svg') no-repeat center/contain;
        }
    }

    &__callback {
        padding: 10px;

        @media (max-width: $xl) {
            height: 35px;
            padding: 10px 12px;
        }

        @media (max-width: $md) {
            display: none;
        }
    }
}
