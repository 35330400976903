.page {

    &__title {
        font-family: 'FiraSans';
        font-size: 3rem;
        margin: 60px 0 40px;

        @media (max-width: $xl) {
            margin: 30px 0;
        }
    }

    &__wrapper {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }

    &__top {
        background: #ebebeb;
        border-bottom: 1px solid #ebebeb;

        @media (max-width: $md) {
            width: 100%;
            z-index: 100;
        }
    }

    &__middle {
        flex: 1 0 auto;
        background-color: $bg-clr;
        position: relative;
        overflow: hidden;
        margin-top: 75px;

        @media (max-width: $xl) {
            margin-top: 125px;
        }

        @media (max-width: $md) {
            margin-top: 50px;
        }
    }

    &__bottom {
        background: #ebebeb;
        flex: 0 0 auto;
    }
}
