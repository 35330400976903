input {
    padding: 10px 20px 10px;
    box-sizing: border-box;
    border: 1px solid $light-grey-clr;

    &[type='number'] {
        -moz-appearance: textfield;
    }

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}


.select {
    cursor: pointer;
    position: relative;
    border: 1px solid $one-more-grey-clr;
    box-sizing: border-box;
    padding: 0 35px 0 20px;

    &__check {
        height: 40px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:before {
            display: none;
        }
    }

    &:after {
        content: "";
        background: url('../images/svg/arrow.svg') no-repeat center/contain;
        position: absolute;
        right: 10px;
        top: calc(50% - 5px);
        width: 22px;
        height: 10px;
    }

    &__name {
        color: $grey-text-clr;
        font-weight: 300;
        display: block;
        position: absolute;
        top: -23px;
        left: 0;
    }

    &__single {
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;

        & input[type='radio'] {
            @include visibly-hidden;

            &:checked + span {
                color: $link-clr;
            }
        }

        & > label {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    
    &__label {
        cursor: pointer;
    }

    &__drop {

        list-style-type: none;
        z-index: 50;
        height: 0;
        visibility: hidden;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        left: 0;
        background: #fff;
        min-width: calc(100% + 2px);
        margin-left: -1px;
        border: 1px solid $one-more-grey-clr;
        padding: 10px 15px;
        box-sizing: border-box;
        max-height: 370px;

        &-show {
            height: 100%;
            display: flex;
            align-items: center;
            color: var(--grey-clr);
            white-space: nowrap;
            overflow: hidden;
        }

        &--vis {
            visibility: visible;
            height: auto;
        }
    }


    &--fill {
        border-color: $main-blue-clr;
    }
}

.input {
    position: relative;
    display: flex;

    &__real {
        max-width: 100%;
        cursor: pointer;
        border: 1px solid $one-more-grey-clr;
        box-sizing: border-box;

        @media (max-width: $md) {
            width: 100%;
        }

        &:focus {
            border-color: $main-blue-clr;
            z-index: 2;
        }

        &--fill {
            border-color: $main-blue-clr;
        }
    }

    &__label {
        @include simple-label;
    }

    & textarea {
        width: 100%;
        resize: none;
        height: 150px;
        padding: 20px 20px 15px;
    }

    &--wide {
        width: 100%;

        & .input__real {
            width: 100%;
        }
    }

    &--triple {

        &:before,
        &:after {
            @include pseudo-el;
            background-color: $one-more-grey-clr;
            width: 1px;
            height: calc(100% - 10px);
            top: 5px;
            left: 33%;
            z-index: 1;
        }

        &:after {
            left: 66%;
        }

        & .input__real {
            width: 33%;
            padding: 15px 10px;
            position: relative;

            @media (max-width: $md) {
                padding: 15px;
            }

            &:not(:last-child):not(:focus) {
                border-right: none;
            }
            &:not(:first-child):not(:focus) {
                border-left: none;
            }
        }
    }
}

.attach {
    position: relative;
    display: flex;
    align-items: center;
    
    &__label {
        @include simple-label;
    }

    &__real {
        @include visibly-hidden;
    }

    &__btn {
        max-width: 225px !important;
    }

    &__file-name {
        margin-left: 30px;
        color: $grey-text-clr;
        max-width: calc(50% - 15px);
        word-break: break-all;
    }
}

.toggle {

    &__btn {

        @include sqare(0px);
        vertical-align: middle;
        cursor: pointer;
        text-indent: -9999px;
        width: 40px;
        height: 20px;
        background: $main-blue-clr;
        display: inline-block;
        border-radius: 100px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: linear-gradient(180deg, #E8F0FF 0%, #D3DFF0 100%);
            transition: 0.3s;
        }

        &--with-m {
            margin:  0 15px;
        }

        &--disabled {
            background: $grey-text-clr;
        }

        &--reverse {
            &:after {
                left: unset;
                right: 2px;
            }
        }
    }

    &__input {
       @include visibly-hidden;

        &:checked + .toggle__btn {
            background: $main-blue-clr;
        }

        &:checked + .toggle__btn:not(.toggle__btn--reverse):after {
            left: calc(100% - 2px);
            transform: translateX(-100%);
        }

        &:checked + .toggle__btn.toggle__btn--reverse:after {
            right: calc(100% - 2px);
            transform: translateX(100%);
        }
    }

    &__state {
        cursor: pointer;
        color: $light-text-clr;

        &--active {
            color: $text-clr;
        }
    }
}


.radio {
    display: flex;
    position: relative;

    &__button {
        height: 100%;
        flex-grow: 1;

        & > input {
            @include visibly-hidden;
        }

        & > span {
            @include flex-center;
            cursor: pointer;
            height: 100%;
            margin: 0 0 0 0px;
            border: 1px solid $one-more-grey-clr;
            border-right: 1px solid transparent;
            transition: all .3s ease;
        }

        &:last-child > span {
            border: 1px solid $one-more-grey-clr;
        }

        & input:checked + span {
            color: #fff;
            background: $main-blue-clr;
            box-shadow: inset 0 3px 6px rgba(0, 0, 0, .2);
            border: 1px solid transparent;
        }

        & input:disabled + span {
            background: $one-more-grey-clr;
            box-shadow: inset 0 0 13px rgba(0, 0, 0, .2);
            border: 1px solid transparent;
        }

        & input:checked:disabled + span {
            color: #fff;
            background: $grey-text-clr;
            box-shadow: inset 0 3px 6px rgba(0, 0, 0, .2);
            border: 1px solid transparent;
        }

        & input:focus + span {
            border: 1px solid $main-blue-clr;
        }

        & input:focus:active + span {
            border: 1px solid transparent;
        }
    }

    &__label {
        @include simple-label;
    }
}

.range {
    position: relative;
    display: flex;

    &__label {
        @include simple-label;
    }

    &__input {
        width: 50%;
        cursor: pointer;
        border: 1px solid $one-more-grey-clr;
        box-sizing: border-box;

        &:focus {
            border-color: $main-blue-clr;
        }

        &--fill {
            border: 1px solid $main-blue-clr;
        }
    }
}

.button {
    @include flex-center;
    padding: 15px 30px;
    color: #fff;
    border: 1px solid transparent;
    max-width: 275px;
    width: 100%;
    background-color: $main-orange-clr;
    box-sizing: border-box;
    font-family: 'Roboto', Arial;
    cursor: pointer;
    transition: all .2s ease;

    $hovered-box-shadow: 0 4px 15px -2px rgba(0, 0, 0, .2);

    @media (max-width: $md) {
        margin:  0 auto;
    }

    &:hover {
        background-color: #ffc763;
        box-shadow: $hovered-box-shadow;
    }

    &:active {
        background-color: #e39407;
        box-shadow: $hovered-box-shadow, 0 0 5px 0 rgba(0, 0, 0, .2) inset;
    }

    &:focus {
        border: 1px solid $main-blue-clr;
    }

    &:active:focus {
        border: 1px solid transparent;
    }

    &--small {
        width: unset;
        padding: 15px;
        font-size: .87rem;
    }

    &--blue {
        background-color: $light-blue-clr;
        color: $blue-text-clr;

        &:hover {
            background-color: #afcef1;
        }

        &:active {
            background-color: #7eaee3;
        }
    }

    &--margin-top {
        margin-top: 25px;
    }

    &--center {
        margin: 0 auto;
    }

    &--pressed {
        background-color: #e39407;
        box-shadow: $hovered-box-shadow, 0 0 5px 0 rgba(0, 0, 0, .2) inset;
    }
}

.text-button {
    cursor: pointer;
    color: $link-clr;
    transition: all .1s ease-out;
    background-color: transparent;
    border: 1px solid transparent;

    &:hover {
        color: $main-blue-clr;
    }

    &:active {
        color: $blue-text-clr;
    }
}

.check {

    display: flex;
    padding-left: 2.1rem;
    position: relative;
    cursor: pointer;

    &:hover,
    & a:hover {
        color: $link-clr;
    }

    &__input {
        @include visibly-hidden;

        &:checked ~ .check__box {
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.166 2.458L5.75 11.873.834 6.957 0 7.79l5.75 5.75L16 3.294l-.834-.835z' fill='%233C6FA1'/%3E%3C/svg%3E")
                        no-repeat center/contain #fff;
            box-shadow: 0 0 0 1px $link-clr;
        }

        &:focus ~ .check__box {
            box-shadow: 0 0 0 1px $main-blue-clr;
        }

        &:checked:focus ~ .check__box {
            box-shadow: 0 0 0 1px $link-clr,
                        0 0 0 2px $main-blue-clr;
        }

        &:disabled ~ .check__label {
            color: $grey-text-clr;
        }

        &:checked:disabled ~ .check__box {
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.166 2.458L5.75 11.873.834 6.957 0 7.79l5.75 5.75L16 3.294l-.834-.835z' fill='%23848993'/%3E%3C/svg%3E")
                        no-repeat center/contain;
            box-shadow: 0 0 0 1px $one-more-grey-clr;
        }
    }

    &__box {
        position: absolute;
        @include sqare(1.5rem);
        background-color: #fff;
        border: 4px solid #fff;
        left: 1px;
        box-sizing: border-box;
        box-shadow: 0 0 0 1px $one-more-grey-clr;

        &--small {
            @include sqare(1.125rem);
        }
    }

    &__label {
        line-height: 1.5;
        cursor: pointer;
    }

}


.file-upload {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;

    & span {
        color: $link-clr;
        padding: 5px 10px;
    }

    & input[type="file"] {
        position: absolute;
        top: 0;
        left: calc(50% - 85px);
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        height: unset;
        width: 170px;

        &:focus ~ span {
            border: 1px solid $main-blue-clr;
        }
    }
}
