@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/Fira_Sans/FiraSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/Fira_Sans/FiraSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/Fira_Sans/FiraSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/Fira_Sans/FiraSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/Fira_Sans/FiraSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/Fira_Sans/FiraSans-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/Fira_Sans/FiraSans-BoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/Fira_Sans/FiraSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'FiraSans';
    src: url('../fonts/Fira_Sans/FiraSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    /*font-display: swap;*/
}