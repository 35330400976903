@mixin clearfix {
    &::after {
        clear: both;
        content: '';
        display: table;
    }
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin img-center {
    @include flex-center;
    overflow: hidden;
    object-fit: cover;
}

@mixin wrap-reset {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
}

@mixin simple-label {
    display: block;
    position: absolute;
    top: -23px;
    color: $grey-text-clr;
    font-weight: 300;
}

@mixin pseudo-el {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}

@mixin visibly-hidden {
    position: absolute;
    @include sqare(1px !important);
    overflow: hidden !important;
    clip: rect(0 0 0 0);
    padding: 0;
    margin: 0;
}

@mixin link {
    color: $link-clr;
    transition: color .1s ease;

    &:hover {
        color: $main-blue-clr;

        &:before,
        &:after {
            color: $main-blue-clr;
        }
    }

    &:active {
        color: $blue-text-clr;

        &:before,
        &:after {
            color: $blue-text-clr;
        }
    }
}

@mixin sqare($s) {
    width: $s;
    height: $s;
}

@mixin wrap {
    max-width: $xl;
    margin: 0 auto;

    @media (max-width: $xl) {
        max-width: calc(100% - 60px);
    }

    @media (max-width: $md) {
        max-width: calc(100% - 30px);
    }


    &--flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@mixin mobile-menu {

    & .ui-accordion-header {
        height: 50px;
        background: transparent;
        padding: 0;
        align-items: center;
        display: flex;

        & a {
            color: #fff;
        }
    }

    & .ui-accordion-content {
        padding: 10px 0 0;
        background: transparent;

        & a {
            color: $light-blue-text-clr;
            margin-bottom: 10px;

            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
    }
    & .ui-accordion-header-icon {

        @include sqare(20px);
        background: url('../images/svg/arrow-white.svg') no-repeat center/cover;
        transition: transform .3s ease;
        right: 0;
        top: calc(50% - 15px);

        &:before, &:after {
            display: none;
        }

        &.ui-icon-minus {
            transform: rotate(180deg);
        }
    }
}
