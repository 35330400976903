.sell-page {


    &__how-help ul {
        max-height: 180px;
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        margin-bottom: 120px;

        @media (max-width: $xl) {
            max-height: unset;
        }
    }
}