.main-page {
    background-color: $bg-clr;
    padding-bottom: 1px;
    
    &__section {
        
        &--white {
            background-color: #fff;
        }
    }
}