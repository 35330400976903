.footer {

    background-color: $main-blue-clr;

    &__inner {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 30px 50px 15px;
        width: $xl;
    }

    &__separator {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #487DB0;
    }

    &__bottom {
        display: flex;
        padding: 15px 0;
        color: $light-blue-clr;
        justify-content: space-between;

        @media (max-width: $md) {
            flex-direction: column;

            & > div {
                height: 50px;
                display: flex;
                align-items: center;
            }
        }
    }

    &__company {
        color: #6ba0d4;

        @media (max-width: $md) {
            order: 1;
        }
    }

    &__policy > a {
        color: $light-blue-text-clr;
    }
}

.ftop {
    padding: 55px 0 30px;
    display: flex;
    justify-content: flex-start;
    color: #fff;

    @media (max-width: $md) {
        flex-direction: column;
        padding: 40px 0 25px;
    }

    &__logo {
        height: 45px;
        margin-right: 70px;

        @media (max-width: $md) {
            height: 30px;
        }
    }
}

.footer-nav {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    @media (max-width: $xl) {
        flex-wrap: wrap;
    }

    @media (max-width: $md) {
        margin-top: 15px;
    }

    &__title {

        & > a {
            color: #fff;
        }

        @media (max-width: $md) {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            font-size: 1.125rem;
            font-weight: 600;
        }
    }

    &__col {

        @media (max-width: $xl) {
            width: 50%;
            flex-shrink: 0;
            margin-top: 15px;

            &:first-child,
            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @media (max-width: $md) {
            width: 100%;
            display: flex;
            margin-top: 0;
            flex-direction: column;
            justify-content: center;

            &.ui-accordion {
                @include mobile-menu;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin: 15px 0 0;

        @media (max-width: $md) {
            margin: 0;
        }

    }

    &__subtitle {
        line-height: 1.5;
        color: $light-blue-text-clr;
    }

    &__socials {
        display: none;

        @media (max-width: $md) {
            display: flex;
            margin-top: 20px;
        }

        &--in-col {
            display: flex;
            margin-top: 35px;

            @media (max-width: $md) {
                display: none;
            }
        }
    }
}
