@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    /*font-display: swap;*/
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    /*font-display: swap;*/
}