@import 'normalize';
@import 'variables';
@import 'mixins';
@import 'fonts/Roboto';
@import 'fonts/Fira-sans';
@import "libs/jquery-ui.min.css";
@import "libs/jquery.fancybox.min.css";
@import "libs/owl.carousel.min.css";
@import "libs/swiper.min.css";

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Arial';
    outline: none;
    line-height: 1;

    &::selection {
        background-color: $main-orange-clr;
        color: #000;
    }
}

html, body {
    height: 100%;
    font-size: 16px;

    @media (max-width: $xl) {
        font-size: 14px;
    }
}

body {
    display: flex;
    flex-direction: column;
    position: relative;
    color: $text-clr;
}

a {
    text-decoration: none;
    color: $text-clr;
    cursor: pointer;
}

p {
    line-height: 1.5;
}

ul {
    list-style: none;
    margin-left: 1rem;
}

ul li::before {
    @include pseudo-el;
    @include sqare(6px);
    background-color: $main-orange-clr;
    border-radius: 50%;
    left: -1rem;
    top: .5rem;
}

li {
    line-height: 1.5;
    position: relative;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.wrap {
    @include wrap
}

.title {
    padding: 5rem 0 0;
    margin: 0 0 3.75rem;
    font-size: 2.5rem;
    text-align: center;

    @media (max-width: $xl) {
        padding: 4rem 0 0;
        margin: 0 0 3.5rem;
        font-size: 2rem;
    }

    @media (max-width: $md) {
        padding: 3.5rem 0 0;
        font-size: 1.5rem;
    }

    &--small {
        font-size: 2rem;
    }

    &--big {
        font-size: 3.25rem;
    }

    &--center {
        text-align: center;
    }
}

.title,
.subtitle {
    font-family: 'FiraSans', Arial;
    color: $title-clr;
    font-weight: 600;
    line-height: 1;

    &--left {
        text-align: left;
    }

    &--white {
        color: #fff;
    }
}

.subtitle {
    font-size: 1.75rem;
    line-height: 2rem;
    text-align: left;

    @media (max-width: $md) {
        font-size: 1.45rem;
        line-height: 1;
    }

    &--center {
        text-align: center;
    }

    &--blue {
        color: $main-blue-clr;
    }
}

.text-box {
    padding: 42px 35px 42px 40px;
    width: 570px;
    background-color: #fff;
    box-sizing: border-box;

    @media (max-width: $xl) {
        padding: 40px 30px;
        width: calc(50% - 15px);
    }

    @media (max-width: $md) {
        padding: 25px 15px;
        @include wrap-reset;
    }

    &:nth-child(2n) {
        margin-right: 0;

        @media (max-width: $md) {
            margin-right: -15px;
        }
    }

    &__title {
        margin-bottom: 20px;

        @media (max-width: $md) {
            margin-top: 15px;
            font-size: 1.55rem;
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
    }

    &__special {
        margin: 35px auto;
        max-width: 75%;
        color: $main-blue-clr;
        font-weight: bold;
        font-size: 1.125rem;

        @media (max-width: $md) {
            margin: 20px 20px 20px 40px;
        }
    }

    &__bottom {
        display: flex;
        margin-top: 40px;
        align-items: flex-start;

        @media (max-width: $md) {
            flex-direction: column-reverse;
            margin-top: 25px;
        }
    }

    &__info {
        color: $grey-text-clr;
        width: calc(50% - 50px);
        margin-right: 25px;

        @media (max-width: $xl) {
            width: calc(50% - 15px);
            margin-right: 15px;
        }

        @media (max-width: $md) {
            width: 100%;
            margin-top: 30px;
        }
    }

    &--no-margin {
        margin: 0;
    }

    &--wide {
        width: 100%;

        & > li {
            width: 48%;
            display: inline-flex;
        }

        @media (max-width: $md) {
            width: calc(100% + 30px);
        }
    }

    &--mobile-wide {
        @media (max-width: $xl) {
            width: 100%;
        }

        @media (max-width: $md) {
            @include wrap-reset;
        }
    }

    &--big-padding {
        padding: 70px 190px;

        @media (max-width: $xl) {
            padding: 50px;
        }

        @media (max-width: $md) {
            padding: 40px 20px;
            margin-bottom: 0;
        }
    }
}

.tab {
    height: 90px;
    @include flex-center;
    flex-grow: 1;
    padding: 0 3rem;
    color: $link-clr;
    cursor: pointer;
    position: relative;
    line-height: 1.5;

    @media (max-width: $xl) {
        padding: 0 1rem;
    }

    @media (max-width: $md) {
        height: 60px;
        justify-content: flex-start;
        border-bottom: 1px solid $one-more-grey-clr;
    }


    &:not(:nth-last-child(2)):after {
        @include pseudo-el;
        right: 0;
        left: unset;
        top: calc(50% - 38px);
        height: 76px;
        width: 1px;
        background-color: $one-more-grey-clr;

        @media (max-width: $md) {
            display: none;
        }
    }

    &--active {
        color: #fff;
        background-color: $main-blue-clr;

        &:before {
            background-image: url('../images/svg/arrow-white.svg');
            transform: rotate(180deg);
        }

        &::after {
            display: none;
        }

        & + .company-tabs__body-mobile {
            min-height: 200px;
            border-bottom: 1px solid $one-more-grey-clr;
        }
    }

    & ~ &--active {
        &::after {
            display: none;
        }
    }
}

.preloader {
    @include sqare(100%);
    @include flex-center;
    position: absolute;
    top: 0;
    left: 0;

    &:before {
        @include pseudo-el;
        @include sqare(60px);
        background: url($site-template-path + '/images/svg/preloader.svg') no-repeat center/contain;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
    }
    
    &--with-bg {
        background-color: #fffffe90;
    }
}

.fav-star {
    display: block;
    position: relative;
    cursor: pointer;
    @include sqare(20px);
    background: url($site-template-path + '/images/svg/star.svg') no-repeat center/contain;

    &__count {
        @include flex-center;
        display: none;
        @include sqare(12px);
        padding: 3px;
        position: absolute;
        top: -8px;
        right: -8px;
        color: #fff;
        font-size: .65rem;
        background-color: $main-blue-clr;
        border-radius: 50%;
    }
    
    &--filled {
        background-image: url($site-template-path + '/images/svg/star-yellow.svg');
    }
}

.modal {

    &__title {
        margin-bottom: 45px;
        font-size: 1.5rem;
        font-weight: normal;
        text-align: center;
    }

    &__input {
        margin-bottom: 40px;

        & input {
            width: 100%;
        }

        & span {
            top: -20px;
        }

        &--small-mb {
            margin-bottom: 20px;
        }
    }

    &__checkbox {
        font-weight: 300;
        margin-bottom: 15px;
        font-size: .875rem;

        & > label {
            display: flex;
        }

        & .check__field {
            border-width: 1px;
            border-radius: 0;
        }
    }

    &__submit {
        width: 100%;
    }

    &--thank-you {
        padding: 30px 30px 40px;

        & p {
            margin-top: 15px;
        }
    }
}


.ui-accordion {

    &-content {
        border: none;
        border-radius: 0;
    }

    &-header {
        height: 90px;
        padding: 30px 65px;
        background-color: #fff;
        color: $text-clr;
        font-size: 1.125rem;
        font-weight: 600;
        border-radius: 0;
        border: none;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: $md) {
            padding: 35px 15px;
        }

        &-icon {
            position: absolute;
            right: 65px;
            top: calc(50% - 8px);
            margin-top: 0;

            @media (max-width: $md) {
                right: 30px;
            }

            &:before {
                @include pseudo-el();
                height: 2px;
                background-color: $main-blue-clr;
                width: 100%;
                top: calc(50% - 1px);
            }

            &:after {
                @include pseudo-el();
                height: 2px;
                background-color: $main-blue-clr;
                width: 2px;
                left: calc(50% - 1px);
                top: calc(50% - 1px);
                transition: all .3s ease-out;
            }

            &.ui-icon-plus {

                &:after {
                    top: 0;
                    height: 100%;
                }
            }
        }
    }
}

.owl-carousel {

    & .owl-stage-outer,
    & .owl-stage,
    & .owl-item {
        height: 100%;
    }

    .owl-dots {
        position: absolute;

        & .owl-dot {
            @include sqare(8px);
            background-color: #fff;
            border-radius: 50%;
            margin-right: 15px;
            transition: all .3s ease;

            &.active {
                background-color: $main-orange-clr;

                & span {
                    height: 20px;
                    width: 20px;
                    background: $main-orange-clr;
                    display: block;
                    margin: -10px -6px;
                    border-radius: 50%;
                    opacity: .3;
                }
            }
        }
    }
}

.fancybox-slide {

    &--html {
        .modal {
            max-width: 400px;
            padding: 35px 50px 55px;
        }
    }

    .fancybox-close-small {
        padding: 20px 20px 0 0;
        box-sizing: content-box;
        height: 20px;
        width: 20px;
    }
}

.cluster-icon-text {
    @include flex-center;
    height: 100%;
    font-size: 1.125rem;
    color: #fff
}

.static ol,
.static ul {
    padding: 15px 0 15px 15px;
}


@import 'blocks/input-fields';
@import 'blocks/main-page';
@import 'blocks/page';
@import 'blocks/header';
@import 'blocks/socials-row';
@import 'blocks/mobile-menu';
@import 'blocks/footer';
@import 'blocks/top-nav';
@import 'blocks/back-to-list';

@import 'blocks/main/main-nian';
@import 'blocks/main/main-info';

@import 'blocks/sell-page';
@import 'blocks/services/mortgage/how-we-can-help';