.main-nian {

    background: no-repeat center/cover;

    &__wrap {
        display: flex;
        padding: 75px 0;
        align-items: flex-end;
        justify-content: space-between;

        @media (max-width: $xl) {
            padding: 60px 0;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }

    &__info {
        z-index: 5;
        width: 550px;

        @media (max-width: $md) {
            width: 100%;
        }
    }

    &__filter {
        z-index: 5;

        @media (max-width: $xl) {
            margin-bottom: 50px;
        }
    }
}