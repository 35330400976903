.how-we-can-help {
    display: flex;
    flex-wrap: wrap;

    & li {
        width: 49%;
        display: inline-flex;
        padding-right: 35px;
        box-sizing: border-box;

        @media (max-width: $xl) {
            &:nth-child(2n) {
                padding-right: 0;
            }
        }

        @media (max-width: $md) {
            width: 100%;
            padding: 0;
        }
    }
}