.top-nav {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $xl) {
        height: 50px;
        padding: 0 30px;
        border-bottom: 1px solid #ebebeb;
        background-color: #fff;
    }

    &:hover &__link--selected {
        border-color: transparent;
    }

    &__link {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-grow: 1;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;

        & > a {
            @include sqare(100%);
            @include flex-center;
        }

        @media (max-width: $xl) {
            flex-grow: 0;
        }

        &--selected {
            border-color: $main-orange-clr;
        }

        &--has-child:hover {
            background-color: $bg-clr;

            & > div {
                opacity: 1;
                height: auto;
                padding: 40px 20px;

                &.top-nav__body--wide {
                    padding: 80px 20px 40px;
                }
            }
        }

        &:hover {
            border-color: $main-orange-clr !important;
        }
    }

    &__body {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: $bg-clr;
        width: max-content;
        top: calc(100% + 2px);
        left: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        z-index: 10;
        transition: all .2s ease;
        max-height: 420px;
        flex-flow: column wrap;
        border: 1px solid #eee;

        &--wide {
            width: 800px;

            @media (max-width: $xl) {
                width: 680px;
            }
        }
    }

    &__subbody {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 15px;
    }

    &__sublink {

        display: flex;
        font-weight: 500;
        align-items: center;
        border-bottom: 2px solid transparent;
        padding-bottom: 10px;

        &:hover {
            border-color: $main-orange-clr;
        }

        & > img {
            @include sqare(20px);
            margin-right: 1rem;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &--simple {
            color: $grey-text-clr;
            margin-bottom: 7px !important;
            padding-bottom: 5px;
            margin-left: calc(20px + 1rem);

            & + .top-nav__sublink:not(&) {
                margin-top: 13px;
            }
        }
    }

    &__toggle {
        position: absolute;
        top: 30px;
    }
}