.main-info {

    &__title {
        color: #fff;
        text-align: left;
        margin-bottom: 35px;
    }

    &__body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: $md) {
            width: 100%;
        }
    }

    &__fact {
        width: calc(25% - 28px);
        color: #fff;
        font-size: .875rem;
        line-height: 1.5;

        &:not(&:nth-child(4n)) {
            margin-right: 25px;
        }

        & span {
            font-family: 'FiraSans', Arial;
            display: block;
            font-size: 3rem;
            font-weight: bold;
            background: linear-gradient(to right, #fae7a9 0%, #f2b744 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: #fae7a9;
        }

        @media (max-width: $md) {
            &:not(&:nth-child(2n)) {
                margin-right: 25px;
            }
            width: calc(50% - 13px);
        }
    }
}